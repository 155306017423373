<template>
  <div class="doctor-prescription p-5">
    <h1 class="title mb-4">UNGGAH ASURANSI</h1>
    <!-- Added form for detailed information -->
    <a-form :model="profileData" class="p-5">
      <div class="row form-item ">
        <div class="col-sm-2 label-col"><label>Unggah Asuransi :</label></div>
        <div class="col-12 col-sm-10">
          <p class="text-left m-0 mb-3">
            Pastikan anda mengunggah foto asuransi secara lengkap dan jelas
          </p>
          <a-upload
            class="upload-section"
            list-type="picture-card"
            :file-list="fileList"
            @change="handleChange"
            :before-upload="() => false"
          >
            <div>
              <a-icon type="camera" />
              <div>Unggah Foto</div>
            </div>
          </a-upload>
        </div>
      </div>
      <a-button type="primary" @click="handleSubmit">KIRIM</a-button>
    </a-form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { Upload, Form, Input, Button } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  uploadLabImage,
  uploadLabResult,
  getPatientProgram,
  createLabTest,
  uploadInsuranceImage,
  uploadInsuranceResult,
  updateProgramInsurance,
  updateTestLab,
} from '../../../services/axios/pulih'
export default {
  components: {
    'a-upload': Upload,
    'a-form': Form,
    // 'a-input': Input,
    'a-button': Button,
  },
  setup() {
    const fileList = ref([])
    const profileData = ref({
      namaDokter: 'John Doe',
      jenisTerapi: 'LYNPARZA',
      email: 'johndoe@example.com',
    })
    const store = useStore()
    const handleChange = ({ fileList: newFileList }) => {
      fileList.value = newFileList.slice(-1) // Batasi hanya 1 gambar yang diunggah
    }
    const route = useRoute()
    const router = useRouter()
    const dataProgramPatient = ref()
    onMounted(async () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const res = await getPatientProgram(userData.user.code)
      dataProgramPatient.value = res
      await updateTestLab(
        dataProgramPatient.value.programs[0].id,
        dataProgramPatient.value.testLabs[0].id,
      )
      // console.log(res, 'inidatapasien')
      // console.log(res.testLabs, 'iniLab')
    })
    const handleSubmit = async () => {
      if (fileList.value.length === 0) {
        console.error('No file selected for upload.')
        return
      }

      const file = fileList.value[0].originFileObj // Ambil file yang akan diupload
      try {
        const res = await uploadInsuranceImage('Lab Test Result', file)
        console.log('Upload response:', res)
        let testLabId = dataProgramPatient.value.testLabs[0].id
        const { data } = await uploadInsuranceResult(testLabId, res.data)
        // Redirect atau logika tambahan setelah upload sukses
        console.log(data[0].id, 'inihasilcreateinssurance')
        if (data.length) {
          await updateProgramInsurance(dataProgramPatient.value.programs[0].id, data[0].id)
          router.push(`/mainProgram`)
        }
      } catch (error) {
        console.error('Upload failed:', error)
      }
    }

    return {
      fileList,
      handleChange,
      handleSubmit,
      profileData,
    }
  },
}
</script>

<style scoped>
.doctor-prescription {
  /* max-width: 400px; */
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
  text-align: center;
}

.doctor-info div {
  margin-bottom: 10px;
  text-align: left;
}

.upload-section {
  /* margin: 20px 0; */
}

.title {
  color: #333;
}

.label-col {
  text-align: right;
  padding-right: 15px;
}

.form-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.col-12 {
  flex: 1;
}

.col-sm-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-sm-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
</style>
